/**
 * Class representing Amplify.
 */
class Amplify {
  constructor() {
    this.subscriptions = {};
    this.requestResources = {};
    this.storeTypes = {};
    this.defaultStoreType = null;
    this.initRequest();
    this.initStorage();
    this.subscribeCache();
    this.requestTypes = {
      /**
       * Defines an AJAX request type.
       * @param {Object} defnSettings - Default settings for the AJAX request.
       * @returns {Function} - A function that handles the AJAX request.
       */
      ajax: defnSettings => (settings, request) => {
        const { type, url, data, dataType, topic, cache } = {
          ...defnSettings,
          ...settings,
        };

        /**
         * Handles the response from the AJAX request.
         * @param {Object} data - The data returned from the AJAX request.
         * @param {string} status - The status of the AJAX request.
         */
        const handleResponse = (data, status) => {
          debugit('SSOSWITCH: handleResponse', data, status);
          if (status === 'success') {
            if (typeof settings.success === 'function') {
              settings.success(data, status);
            }
            this.publish(topic || settings.resourceId, data);
          } else if (typeof settings.error === 'function') {
            settings.error(data, status);
          }
        };

        debugit('SSOSWITCH: attempting ' + dataType + ' request at ' + url);

        // if caching was enabled (any type for now) we'll use in-memory caching to prevent multiple requests on a given page-load
        const cacheKey = this.cache._key(settings.resourceId, url, data);
        if (cache && this.cache.memory[cacheKey]) {
          handleResponse(this.cache.memory[cacheKey], 'success');
          return request;
        }
      
        // Convert data object to query string parameters
        const queryParams = new URLSearchParams(data).toString();
      
        debugit('SSOSWITCH: json request made');
        
        fetch(`${url}?${queryParams}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          }
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          handleResponse(data, 'success');
          this.cache.memory[cacheKey] = data;
        })
        .catch(error => {
          debugit('SSOSWITCH: fetch error', error);
          handleResponse(error, 'error');
        });
              
        return request;
      },
    };
    this.cache = {
      memory: {},
      _key(resourceId, url, data) {
        const fullData = url + data;
        return `request-${resourceId}-${fullData.split('').reduce((acc, char) => (acc << 5) - acc + char.charCodeAt(0), 0)}`;
      },
      _default: (() => {
        const memoryStore = {};
        return (resource, settings, ajaxSettings, ampXHR) => {
          const cacheKey = this.cache._key(settings.resourceId, ajaxSettings.url, ajaxSettings.data);
          if (memoryStore[cacheKey]) {
            ampXHR.success(memoryStore[cacheKey]);
            return false;
          }
          ampXHR.success = (success => data => {
            memoryStore[cacheKey] = data;
            setTimeout(() => delete memoryStore[cacheKey], resource.cache);
            success(data);
          })(ampXHR.success);
        };
      })(),
    };
  }

  /**
   * Publishes a message to all subscribers of a topic.
   * @param {string} topic - The topic to publish.
   * @param {...any} args - Arguments to pass to the subscribers.
   * @returns {boolean} - True if no subscriber returned false, otherwise false.
   */
  publish(topic, ...args) {
    if (typeof topic !== 'string') throw new Error('You must provide a valid topic to publish.');
    const subs = this.subscriptions[topic];
    if (!subs) return true;
    return subs.slice().every(sub => sub.callback.apply(sub.context, args) !== false);
  }

  /**
   * Subscribes to a topic.
   * @param {string} topic - The topic to subscribe to.
   * @param {Object} [context] - The context for the callback.
   * @param {Function} callback - The callback to invoke when the topic is published.
   * @param {number} [priority=10] - The priority of the subscription.
   * @returns {Function} - The callback function.
   */
  subscribe(topic, context, callback, priority = 10) {
    if (typeof topic !== 'string') throw new Error('You must provide a valid topic to create a subscription.');
    if (arguments.length === 3 && typeof callback === 'number') {
      priority = callback;
      callback = context;
      context = null;
    } else if (arguments.length === 2) {
      callback = context;
      context = null;
    }
    const subs = this.subscriptions[topic] || (this.subscriptions[topic] = []);
    subs.push({ callback, context, priority });
    subs.sort((a, b) => a.priority - b.priority);
    return callback;
  }

  /**
   * Unsubscribes from a topic.
   * @param {string} topic - The topic to unsubscribe from.
   * @param {Object} [context] - The context for the callback.
   * @param {Function} callback - The callback to remove.
   */
  unsubscribe(topic, context, callback) {
    if (typeof topic !== 'string') throw new Error('You must provide a valid topic to remove a subscription.');
    if (arguments.length === 2) {
      callback = context;
      context = null;
    }
    const subs = this.subscriptions[topic];
    if (!subs) return;
    this.subscriptions[topic] = subs.filter(sub => sub.callback !== callback || (context && sub.context !== context));
  }

  /**
   * Initializes the request object.
   */
  initRequest() {
    this.request = settings => {
      if (typeof settings === 'string') {
        settings = { resourceId: settings };
      }

      const resourceId = settings.resourceId;
      const resource = this.requestResources[resourceId];
      if (!resource) throw new Error(`amplify.request: unknown resourceId: ${resourceId}`);

      const request = { abort: () => {} };
      settings.success = settings.success || (() => {});
      settings.error = settings.error || (() => {});

      if (!!settings.url) {
        // Convert data object to query string parameters
        const queryParams = new URLSearchParams(data).toString();
      
        debugit('SSOSWITCH: json request made');

        fetch(`${settings.url}?${queryParams}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          }
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          settings.success(data, 'success');
          if (settings.topic) {
            this.publish(settings.topic || settings.resourceId, data); // Publish the topic on success
          }
        })
        .catch(error => {
          debugit('SSOSWITCH: fetch error', error);
          settings.error(error, 'error');
        });
              
        return request;
      }

      resource(settings, request);
      return request;
    };

    this.request.define = (resourceId, requestTypeOrHandler, settings) => {
      if (typeof requestTypeOrHandler === 'function') {
        this.requestResources[resourceId] = requestTypeOrHandler;
      } else {
        const requestType = requestTypeOrHandler;
        this.requestResources[resourceId] = this.requestTypes[requestType](settings);
      }
    };
  }

  /**
   * Initializes storage types.
   */
  initStorage() {
    const addStorageType = (type, storage) => {
      this.storeTypes[type] = storage;
      this.defaultStoreType = this.defaultStoreType || type;
    };

    const createFromStorageInterface = (storageType, storage) => {
      addStorageType(storageType, (key, value, options = {}) => {
        const fullKey = `__amplify__${key}`;
        if (value === undefined) {
          const storedValue = storage.getItem(fullKey);
          const parsed = storedValue ? JSON.parse(storedValue) : { expires: -1 };
          if (parsed.expires && parsed.expires <= Date.now()) {
            storage.removeItem(fullKey);
          } else {
            return parsed.data;
          }
        } else {
          if (value === null) {
            storage.removeItem(fullKey);
          } else {
            const serialized = JSON.stringify({
              data: value,
              expires: options.expires ? Date.now() + options.expires : null,
            });
            try {
              storage.setItem(fullKey, serialized);
            } catch (e) {
              this.storeTypes[storageType]();
              try {
                storage.setItem(fullKey, serialized);
              } catch (error) {
                throw new Error('amplify.store quota exceeded');
              }
            }
          }
        }
      });
    };

    // Initialize various storage types
    const storages = { localStorage, sessionStorage };
    for (const type in storages) {
      try {
        storages[type].setItem('__amplify__', 'x');
        storages[type].removeItem('__amplify__');
        createFromStorageInterface(type, storages[type]);
      } catch (e) {}
    }

    // In-memory storage as a fallback
    addStorageType(
      'memory',
      (() => {
        const memory = {};
        const timeout = {};
        return (key, value, options = {}) => {
          if (!key) return { ...memory };
          if (value === undefined) return { ...memory[key] };
          if (timeout[key]) clearTimeout(timeout[key]);
          if (value === null) {
            delete memory[key];
          } else {
            memory[key] = value;
            if (options.expires) {
              timeout[key] = setTimeout(() => delete memory[key], options.expires);
            }
          }
          return value;
        };
      })(),
    );
  }

  /**
   * Subscribes to cache before ajax requests.
   */
  subscribeCache() {
    this.subscribe('request.before.ajax', (resource, settings, ajaxSettings, ampXHR) => {
      const cacheType = resource.cache;
      if (cacheType) {
        return this.cache[cacheType in this.cache ? cacheType : '_default'].apply(this, arguments);
      }
    });
  }
}

const amplify = new Amplify();
export { amplify };
