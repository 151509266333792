import { amplify } from './acs-amplify.js';
import deepEqual from './utils/deep-equals';

export function ssoModule() {
  if (typeof acs === 'undefined') {
    window.acs = { sso: {}, user: {} };
  }

  window.acs.sso.getStatus = function (isCached = true) {
    // do an ajax hit against the SSO service using JSON
    const promise = new Promise((resolve, reject) => {
      amplify.request({
        resourceId: isCached ? 'sso.status' : 'sso.status.noCache',
        success: function (data) {
          const previousUser = { ...window.acs.user }; // Store previous user state before updating
          window.acs.user = data.results;

          if (data.succ) {
            if (window.acs.user.firstname && window.acs.user.lastname) {
              window.acs.user.fullname = `${window.acs.user.firstname} ${window.acs.user.lastname}`;
            }
            if (window.acs.user.membertype) {
              window.acs.user.communitymember =
                window.acs.user.membertype === 'COMMUNITY_ASSOCIATE' || window.acs.user.membertype === 'COMMUNITY_MEMBER';
            }
            if (window.acs.user.packagecode) {
              window.acs.user.haspackage =
                window.acs.user.packagecode === 'BASIC' || window.acs.user.packagecode === 'PREMIUM' || window.acs.user.packagecode === 'STANDARD';
            } else {
              window.acs.user.haspackage = false;
            }
          }

          // Only publish the event if there's a meaningful change in the user state
          if (!deepEqual(previousUser, window.acs.user)) {
            amplify.publish('sso.status.changed', window.acs.user, true);
          }

          resolve(data);
        },
        error: function (data, status) {
          window.acs.user = { loggedin: false };

          if (status === 'abort') {
            reject('could not connect to sso.getStatus service');
          } else {
            reject('sso.getStatus returned an error');
          }
        },
      });
    });

    promise.always = function (callback) {
      promise.then(callback).catch(callback);
      return promise;
    };

    return promise;
  };

  // should return sso data immediately if it already exists
  // should return so data from local storage if exists
  // else it should get sso data from a url/ ajax hit
  // else it should return an error message that data could not be found
  window.acs.sso.init = function () {
    // setup user object
    window.acs.user = {
      loggedin: false,
    };

    /**
     * Listen to the sso status change, and retransmit after mutating the acs variable
     */
    amplify.subscribe('sso.status', function (newData) {
      newData = newData || {};

      const previousUser = { ...window?.acs?.user }; // Store previous user from global var

      if (newData && newData.succ && newData.results) {
        window.acs.user = newData.results;
        if (acs.user.firstname && acs.user.lastname) {
          window.acs.user.fullname = window.acs.user.firstname + ' ' + window.acs.user.lastname;
        }
        if (acs.user.membertype) {
          window.acs.user.communitymember = window.acs.user.membertype === 'COMMUNITY_ASSOCIATE' || window.acs.user.membertype === 'COMMUNITY_MEMBER';
        }
      } else {
        window.acs.user = { loggedin: false };
      }

      // Compare previous and current acs.user objects
      if (!deepEqual(previousUser, window.acs.user)) {
        console.log('sso status has changed, publishing event from acs-sso');
        amplify.publish('sso.status.changed', window.acs.user, newData.succ || false);
      }

      return;
    });

    // init should return the getStatus promise
    return this.getStatus();
  };
}
ssoModule();
